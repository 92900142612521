const en = {
    add: 'add',
    annualTbAttestation: 'Annual TB Attestation',
    annualEducationAttestation: 'Annual Attestation of Core Competency Education',
    answer: 'answer',
    associateDegree: "Associate's Degree",
    award: 'award',

    bachelorDegree: "Bachelor's Degree",
    back: 'back',
    backToCredentials: 'back to credentials',
    
    cancel: 'Cancel',
    certification: 'certification',
    chooseFile: 'choose file',
    clearSignature: 'clear signature',
    city: 'city',
    continue: 'continue',
    courses: 'Mandatories',
    course: 'Mandatory',
    currentlySelected: '(Currently Selected)',

    date: 'date',
    dateRange: 'date range',
    dateReceived: 'date received',
    days: 'days',
    day: 'day',
    degree: 'degree',
    delete: 'Delete',
    department: 'department',
    doctoralDegree: "Doctoral Degree",
    document: 'document',
    documentDeletePaneMessage: 'Are you sure you want to delete this document?',
    done: 'Done',
    doYouHaveATiter: 'Do you have a titer?',
    driversLicense: "Driver's License",

    edit: 'edit',
    education: 'Education',
    electromagneticRadiation: 'Electromagnetic Radiation',
    eligibleForRehire: 'eligible for rehire',
    email: 'email',
    employment: 'employment',
    employeeSignature: 'employee signature',
    employeeSignatureHint: 'Use your cursor or finger to sign below.',
    endDate: 'end date',
    enter: 'Enter',
    examDate: 'exam date',
    expired: 'Expired',
    expires: 'Expires',

    facilityName: 'facility name',
    facilityType: 'facility type',
    firstName: 'first name',
    file: 'file',
    fluDeclination: 'Influenza Declination',
    form: 'form',
    freeOfActiveDisease: 'Free of Active Disease',
    front: 'front',

    graduationDate: 'Graduation Date',

    hepBDeclination: 'Hepatitis B Declination',
    hospital: 'hospital',

    iCurrentlyWorkHere: 'I currently work here',
    image: 'image',
    immunization: 'immunization',
    injection: 'injection',
    invitationNote: 'invitation note', 
    issueDate: 'issue date',
    issueExpireDate: 'expiration date',
    issuerCity: 'City of Issuer',
    issuerLocation: 'Issuer Location',
    issuerState: 'State of Issuer',

    jobTitle: 'job title',

    kind: 'kind',

    lastName: 'last name',
    letterOfRecommendation: 'Letter of Recommendation',
    license: 'license',

    masterDegree: "Master's Degree",
    months: 'months',
    month: 'month',

    name: 'name',
    no: 'no',
    nonSupportedDocumentMessage: 'Document type is currently not supported.',
    noIssuingState: 'No location provided',
    noReferenceOrganization: 'No organization provided',
    noReferenceTitle: 'No title provided',
    notApplicable: 'N/A',
    notFoundMessage: '404: Page not found',
    expiredReference: 'Error: Session Expired',
    notProvided: 'not provided',
    number: 'number',

    of: 'of',
    organization: 'organization',
    orientation: 'orientation',
    other: 'Other',

    passport: 'passport',
    pending: 'pending',
    phone: 'phone',
    photo: 'photo',
    photoOfCertification: 'Photo of Certification',
    photoOfDiploma: 'Photo of Diploma',
    photoOfLicense: 'Photo of License',
    physicalHealthStatement: 'Physical Health Statement',
    positionTitle: 'position title',
    ppd: 'PPD',
    present: 'Present',
    preliminaryBackgroundRelease: 'Preliminary Background Release',
    primary: 'Primary',
    program: 'program',

    reference: 'reference',
    referenceDeletePaneMessage: 'Are you sure you want to delete this reference?',
    renew: 'renew',
    resume: 'resume',
    resume_cv: 'Resume / CV',
    result: 'result',
    roles: 'roles',

    save: 'Save',
    schoolName: 'school name',
    select: 'Select',
    selectRole: 'select role',
    selectFile: 'Select file',
    selectDegreeOrProgram: 'Select degree/program',
    selectState: 'Select state',
    skillsChecklists: 'Skills Checklists',
    specialties: 'Specialties',
    specialty: 'specialty',
    ssCard: 'Social Security Card',
    startedOn: 'started on',
    startDate: 'start date',
    state: 'state',

    tbSkinTest: 'TB Skin Test',
    tests: 'Tests',
    title: 'title',
    titer: 'titer',
    twoStep: '2 Step',
    type: 'type',

    vaccination: 'vaccination',
    varicellaDeclination: 'Varicella Declination',
    verified: 'verified',
    view: 'View',
    
    workExperience: 'work experience',
    workExperienceDeletePaneMessage: 'Are you sure you want to delete this work experience?',

    xRay: 'X-Ray',

    years: 'years',
    year: 'year',
    yes: 'yes'
}

export default en