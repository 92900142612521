import React from 'react'

import LoadingPane from '../../components/Shared/LoadingPane'

const PasswordReset = (props) => {
    const { goToBaseRoute, handleChange, save, state } = props
    const { error, loading, /*password*/ } = state

    return (
        <div className='password-reset-view full-screen-view'>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <div className='una-logo' onClick={goToBaseRoute}>
                <img src={require('../../theme/assets/una-logo-2x.png')} alt='una-logo' />
            </div>

            <div className='content-frame'>
                <div className='centered-frame'>
                    <div className='header'>Create your password</div>

                    <div className='message'>Please create a new password that has a minimum of 6 characters.</div>

                    <div className='form-frame'>
                        {/* <input
                            defaultValue={password}
                            className='form-input'
                            onChange={e => handleChange('password', e.target.value)}
                            placeholder='Current Password'
                            type='password'
                        /> */}

                        <input
                            className='form-input'
                            onChange={e => handleChange('password1', e.target.value)}
                            placeholder='New Password'
                            type='password'
                        />

                        <input
                            className='form-input'
                            onChange={e => handleChange('password2', e.target.value)}
                            placeholder='Confirm New Password'
                            type='password'
                        />

                        <div className={error ? 'error-label red' : 'hide'}>{error}</div>

                        <button className='reset-password-button' onClick={save}>Save Password</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordReset