import React from "react";
import { graphql } from "react-apollo";

import { flowRight as compose, orderBy } from "lodash";
import Cookie from "js-cookie";

import UserQuery from "../../graphql/queries/Course/Chapters";

import CourseChapter from "../../components/Course/Chapter";

import LoadingPane from "../../components/Shared/LoadingPane";

class CourseChapterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: window.innerWidth < 1000,
      onload: true,
    };
  }

  componentDidMount() {
    this.setup();

    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  setup = () => {
    const {
      match,
      userQuery: { loading, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      let urlChapter = match.params.chapter,
        chapter = user.courseTaken.course.chapters.find(
          (c) => c.id === urlChapter
        );

      this.setState({
        ...user.courseTaken,
        chapter: urlChapter ? this.setupChapter(chapter) : null,
        onload: false,
      });
    }
  };

  setupChapter = (chapter) => {
    let elem = document.createElement("div");

    elem.innerHTML = chapter.contentFormatted.replace(/<img[^>]*>/g, (img) =>
      img.replace(/img src/g, 'a target="_blank" href').replace("/>", "></a>")
    );

    for (var i = 0; i < elem.getElementsByTagName("a").length; i++) {
      let node = elem.getElementsByTagName("a")[i];
      const href = node.getAttribute("href");
      if (href.endsWith(".png") || href.endsWith(".jpg")) {
        const img = document.createElement("img");

        img.src = node.getAttribute("href");
        img.setAttribute("width", node.getAttribute("width") * 2);
        img.setAttribute("height", node.getAttribute("height") * 2);

        node.appendChild(img);
      }
    }

    return {
      ...chapter,
      contentFormatted: elem.outerHTML,
    };
  };

  goToChaptersRoute = () => {
    const { match } = this.props;

    let route = `/courses/${match.params.course}/chapters`;

    this.goToRoute(route);
  };

  goToChapterRoute = (chapter) => {
    const { match } = this.props;

    let route = `/courses/${match.params.course}/chapters/${chapter.id}`;

    this.goToRoute(route);
  };

  goToRoute = (route) => {
    this.props.history.push(route);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleChapter = (chapter) => {
    let chapters = orderBy(this.state.course.chapters, ["rank"]),
      nextChapter = chapters.find((c) => c.rank === chapter.rank + 1);

    if (nextChapter) {
      this.handleChange("chapter", this.setupChapter(nextChapter));
      this.goToChapterRoute(nextChapter);
    } else {
      this.goToChaptersRoute();
    }
  };

  handleResize = () => {
    this.handleChange("mobile", window.innerWidth < 1000);
  };

  render() {
    const { onload } = this.state;

    return onload ? (
      <LoadingPane />
    ) : (
      <CourseChapter
        goToChaptersRoute={this.goToChaptersRoute}
        handleChapter={this.handleChapter}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: "userQuery",
    options: (props) => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
        course: props.match.params.course,
      },
    }),
  })
)(CourseChapterContainer);
