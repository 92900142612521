import ApolloClient from "apollo-boost";
import Cookie from "js-cookie";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  request: (operation) => {
    const headers = {};    
    const org = Cookie.get('org');
    let token = Cookie.get('reference-token') || Cookie.get(`${process.env.REACT_APP_COOKIE_NAME}-token`);

    if (org) {
      headers.org = org;
    } 

    if (token) {
      headers.Authorization = token
    }

    operation.setContext({
      headers
    });

  },
});

export default client;
