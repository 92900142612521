import { gql } from "apollo-boost";

const CredentialsQuery = gql`
  query CredentialsQuery($id: ID, $org: ID!) {
    org(id: $org) {
      id
      attachment(label: "pdf_logo") {
        id
        url
      }
      modules
    }
    user(id: $id) {
      id
      attachments(labels: ["avatar"]) {
        id
        label
        url
      }
      bio
      checklistsTaken(order: "-ended_at") {
        id
        answers {
          id
          question {
            id
            scoreValues
          }
          value
        }
        endedAt
        orgId
        status
        title
      }
      coursesTaken(order: "-ended_at") {
        id
        endedAt
        orgId
        passed
        score
        status
        title
      }
      documents {
        nodes {
          id
          documentNumber
          expiresOn
          kind
          imageUrl
          imageBackUrl
          issuerLocation
          issuerName
          issuedOn
          issuingState
          isVerified
          status
          title
          verificationData
        }
      }
      firstName
      lastName
      profileCompletion
      references {
        id
        completedAtIso
        createdAtIso
        firstName
        lastName
        organization
        status
        title
      }
      roles {
        id
        role
        org {
          id
          attachment(label: "pdf_logo") {
            id
            url
          }
          brandColor
          modules
          title
        }
      }
      specialties {
        id
        isPrimary
        name
        startedOn
      }
      testsTaken(order: "-ended_at") {
        id
        createdAt
        endedAt
        orgId
        passed
        score
        status
        title
      }
      workExperiences {
        id
        city
        endedOn
        kind
        name
        positionTitle
        state
        startedOn
      }
      userSubscription {
        id
        usedCredit
        availedCredit
        status
        subscriptionDate
        expiryDate
        subscription {
          title
        }
      }
    }
  }
`;

export default CredentialsQuery;
