import React from "react";
import { graphql } from "react-apollo";

import { camelCase, flowRight as compose, orderBy } from "lodash";
import Cookie from "js-cookie";

import UserQuery from "../../graphql/queries/Credentials";
import SessionLogoutMutation from "../../graphql/mutations/Session/Logout";

import Credentials from "../../components/Credentials";

import LoadingPane from "../../components/Shared/LoadingPane";
import UserTestRetakeMutation from "../../graphql/mutations/User/Test/Retake";

const responsiveSize = 766;
const refs = {};

class CredentialsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      credentials: {
        certification: [],
        education: [],
        license: [],
        misc: [],
        reference: [],
        workExperience: [],
      },
      isModal: false,
      isRole: false,
      mobile: window.innerWidth < responsiveSize,
      onload: true,
      profileStrengthData: [{ y: 0 }, { y: 100 }],
    };
  }

  componentDidMount() {
    const {
      userQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(this.setup);
    } else {
      this.setup();
    }

    window.addEventListener("resize", this.handleResize);
    window.addEventListener("beforeunload", () => { });
    window.addEventListener("unload", () => { });
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  setup = () => {
    const {
      match,
      userQuery: { loading, org, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      const {
        coursesTaken,
        checklistsTaken,
        documents,
        profileCompletion,
        testsTaken,
        userSubscription,
      } = user;

      let role = user.roles.find((r) => r.org.id === match.params.id);

      if (match.params.id && !role) {
        this.goToRoute("/roles", true);
      } else {
        this.setState(
          {
            ...user,
            checklistsTaken: this.setupData(checklistsTaken, role),
            coursesTaken: this.setupData(coursesTaken, role),
            credentials: this.setupDocuments(documents),
            userSubscription: userSubscription,
            onload: false,
            org,
            profilePicture: user.attachments[0],
            role,
            selected: role || {},
            specialties: orderBy(
              user.specialties,
              ["isPrimary", "startedOn"],
              ["desc", "desc"]
            ),
            testsTaken: this.setupData(testsTaken, role),
          },
          () => {
            this.handleChange("profileStrengthData", [
              { y: profileCompletion },
              { y: 100 - profileCompletion },
            ]);
          }
        );
      }
    }
  };

  setupData = (items, role) => {
    const { match } = this.props;
    let arr = role ? items : items;

    arr = arr.filter((item) => {
      return !item.orgId || item.orgId === match.params.id;
    });
    return orderBy(
      arr,
      ["endedAt", "status", "createdAt"],
      ["desc", "asc", "desc"]
    );
  };

  setupDocuments = (documents) => {
    let credentials = this.state.credentials;

    documents.nodes.forEach((document) => {
      let kind = camelCase(document.kind),
        collection = credentials[kind];

      if (collection) collection.push(document);
      else credentials.misc.push(document);
    });

    return credentials;
  };

  goToRoleRoute = (hide) => {
    const { history } = this.props;
    const { selected } = this.state;

    if (selected) {
      hide();

      Cookie.set("org", selected.org.id);

      setTimeout(() => {
        this.handleChange("role", selected);

        history.push(`/organizations/${selected.org.id}/credentials`);
      }, 300);
    }
  };

  goToRoute = (route, replace) => {
    const { history } = this.props;

    if (replace) {
      history.replace(route);
    } else {
      history.push(route, { previous: history.location.pathname });
    }
  };

  goToNewDocumentRoute = (hide) => {
    const { history } = this.props;
    const { selected2 } = this.state;

    let url = `/documents/${selected2.route}s/create`;

    hide();

    setTimeout(
      () => history.push(url, { previous: history.location.pathname }),
      300
    );
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleResize = () => {
    this.handleChange("mobile", window.innerWidth < responsiveSize);
  };

  handleRole = (isExpand, hide) => {
    if (isExpand) {
      hide();

      setTimeout(() => this.handleChange("isRole", true), 300);
    } else {
      this.setState({
        isExpand: false,
        isRole: true,
      });
    }
  };

  handleSectionExpand = (node) => {
    let headerNode = node.childNodes[0],
      listNode = node.childNodes[1],
      isExpand = node.className.includes("expanded") ? true : false,
      arrowNode = headerNode.childNodes[1].querySelector(".arrow-icon");

    if (isExpand) {
      node.classList.remove("expanded");
      node.style.height = `${headerNode.getBoundingClientRect().height}px`;

      arrowNode.classList.remove("down");
    } else {
      node.classList.add("expanded");
      node.style.height = `${headerNode.getBoundingClientRect().height +
        listNode.getBoundingClientRect().height
        }px`;

      arrowNode.classList.add("down");
    }
  };

  logout = () => {
    const { sessionLogoutMutation } = this.props;

    sessionLogoutMutation().then((response) => {
      const {
        data: {
          sessionLogout: { errors, success },
        },
      } = response;

      if (success) {
        Cookie.remove(process.env.REACT_APP_COOKIE_NAME);
        Cookie.remove(`${process.env.REACT_APP_COOKIE_NAME}-token`);
        Cookie.remove("org");

        window.location = "/";
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  handleRetakeTest = (id) => {
    const { userTestRetakeMutation } = this.props;

    userTestRetakeMutation({
      variables: {
        input: {
          id: id
        }
      }
    }).then((response) => {
      const { data: { userTestRetake } } = response

      window.location = `/tests/${userTestRetake.result.id}/start`
    }).catch((errors) => {
      window.alert(errors[0].message);
    })
  }

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Credentials
        {...this.props}
        goToRoute={this.goToRoute}
        goToNewDocumentRoute={this.goToNewDocumentRoute}
        goToRoleRoute={this.goToRoleRoute}
        handleChange={this.handleChange}
        handleSectionExpand={this.handleSectionExpand}
        handleRole={this.handleRole}
        handleRetakeTest={this.handleRetakeTest}
        logout={this.logout}
        refs={refs}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: "userQuery",
    options: ({
      match: {
        params: { id },
      },
    }) => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
        org: id || -1,
      },
    }),
  }),
  graphql(SessionLogoutMutation, { name: "sessionLogoutMutation" }),
  graphql(UserTestRetakeMutation, { name: "userTestRetakeMutation" })
)(CredentialsContainer);
