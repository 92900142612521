import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import Cookie from "js-cookie";

import UserQuery from "../../graphql/queries/Account";
import SessionLoginMutation from "../../graphql/mutations/Session/Login";
import SessionLogoutMutation from "../../graphql/mutations/Session/Logout";
import UserUpdateMutation from "../../graphql/mutations/User/Update";

import Account from "../../components/Account";

import LoadingPane from "../../components/Shared/LoadingPane";

class AccountContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModal: false,
      isRole: false,
      mobile: window.innerWidth < 1000,
      onload: true,
      password: "",
      password1: "",
      password2: "",
    };
  }

  componentDidMount() {
    const {
      userQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(this.setup);
    } else {
      this.setup();
    }

    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  setup = () => {
    const {
      history,
      match,
      userQuery: { loading, org, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      let role = user?.roles.find((r) => r.org.id === match.params.id);

      if (match.params.id && !role) {
        history.replace("/roles");
      } else {
        this.setState({
          ...user,
          onload: false,
          org,
          phoneNumber: this.formatPhoneNumber(user.phoneNumber),
          profilePicture: user.attachments[0] || null,
          role,
          selected: role || {},
        });
      }
    }
  };

  formatPhoneNumber = (str) => {
    let cleaned = ("" + str).replace(/\D/g, ""),
      match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      // let intlCode = (match[1] ? '+1 ' : '')

      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return null;
  };

  goToEditAccountRoute = () => {
    const { history, match } = this.props;

    history.push(
      match.params.id
        ? `/organizations/${match.params.id}/account/edit`
        : "/account/edit"
    );
  };

  goToRoleRoute = (hide) => {
    const { history } = this.props;
    const { selected } = this.state;

    if (selected) {
      hide();

      if (selected.org.id) {
        Cookie.set('org', selected.org.id);
      }

      setTimeout(() => {
        this.handleChange("role", selected);

        history.push(`/organizations/${selected.org.id}/account`);
      }, 300);
    }
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleResize = () => {
    this.handleChange("mobile", window.innerWidth < 1000);
  };

  handleRole = (isExpand, hide) => {
    if (isExpand) {
      hide();

      setTimeout(() => this.handleChange("isRole", true), 300);
    } else {
      this.setState({
        isExpand: false,
        isRole: true,
      });
    }
  };

  logout = () => {
    const { sessionLogoutMutation } = this.props;

    sessionLogoutMutation().then((response) => {
      const {
        data: {
          sessionLogout: { errors, success },
        },
      } = response;

      if (success) {
        Cookie.remove(process.env.REACT_APP_COOKIE_NAME);
        Cookie.remove(`${process.env.REACT_APP_COOKIE_NAME}-token`);
        Cookie.remove('org');

        window.location = "/";
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  resetModal = () => {
    this.setState({
      error: null,
      isModal: false,
      password: "",
      password1: "",
      password2: "",
    });
  };

  resetPassword = (hide) => {
    const { sessionLoginMutation, userUpdateMutation } = this.props;
    const { email, id, password, password1, password2 } = this.state;

    if (!password || !password1 || !password2) {
      this.handleChange("error", "All fields are required.");
    } else if (password1 !== password2) {
      this.handleChange("error", "Passwords do not match.");
    } else if (password1.length < 6) {
      this.handleChange(
        "error",
        "Password does not meet minimum requirement (6 characters)."
      );
    } else {
      this.setState(
        {
          loading: true,
        },
        () => {
          sessionLoginMutation({
            variables: {
              input: {
                email,
                password,
              },
            },
          }).then((response) => {
            const {
              data: { sessionLogin },
            } = response;

            if (sessionLogin.user) {
              userUpdateMutation({
                variables: {
                  input: {
                    id,
                    password: password1,
                  },
                },
              }).then((response) => {
                const {
                  data: {
                    userUpdate: { errors, success },
                  },
                } = response;

                this.handleChange("loading", false);

                if (success) {
                  hide();
                } else {
                  this.handleChange("error", `${errors[0].message}.`);
                }
              });
            } else {
              this.setState({
                error: "Current password is incorrect.",
                loading: false,
              });
            }
          });
        }
      );
    }
  };

  render() {
    const { match } = this.props;
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Account
        {...this.props}
        goToEditAccountRoute={this.goToEditAccountRoute}
        goToRoleRoute={this.goToRoleRoute}
        handleChange={this.handleChange}
        handleRole={this.handleRole}
        logout={this.logout}
        resetModal={this.resetModal}
        resetPassword={this.resetPassword}
        hideLogout={false}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: "userQuery",
    options: ({
      match: {
        params: { id },
      },
    }) => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
      },
    }),
  }),
  graphql(SessionLoginMutation, {
    name: "sessionLoginMutation",
    options: (props) => ({
      client: window.client2,
    }),
  }),
  graphql(SessionLogoutMutation, { name: "sessionLogoutMutation" }),
  graphql(UserUpdateMutation, { name: "userUpdateMutation" })
)(AccountContainer);
