import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import Cookie from "js-cookie";

import SessionLoginMutation from "../../graphql/mutations/Session/Login";
import UserUpdateMutation from "../../graphql/mutations/User/Update";
import PasswordResetMutation from "../../graphql/mutations/Password/Reset";

import PasswordReset from "../../components/PasswordReset";

import LoadingPane from "../../components/Shared/LoadingPane";

class PasswordResetContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      onload: true,
      password1: "",
      password2: "",
    };
  }

  componentDidMount() {
    const { history } = this.props;

    let searchParams = new URLSearchParams(history.location.search),
      email = searchParams.get("e"),
      password = searchParams.get("p"),
      token = searchParams.get("token");

    if (password) {
      this.setState(
        {
          email,
          onload: false,
          password,
        },
        () =>
          history.push(
            `${history.location.pathname}?e=${encodeURIComponent(email)}`
          )
      );
    } else {
      this.setState({
        email,
        onload: false,
        token,
      });
    }
  }

  goToBaseRoute = () => {
    this.props.history.push("/");
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  passwordReset = () => {
    const { history, passwordResetMutation } = this.props;
    const { password1, token } = this.state;

    passwordResetMutation({
      variables: {
        input: {
          token,
          password: password1,
        },
      },
    })
      .then((response) => {
        const { errors } = response.data.passwordReset;

        if (errors) {
          this.setState({
            error: `${errors[0].message}.`,
            loading: false,
          });
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        this.handleChange("loading", false);

        window.alert(error.message);
      });
  };

  sessionLogin = () => {
    const { sessionLoginMutation, userUpdateMutation } = this.props;
    const { email, password, password1 } = this.state;

    sessionLoginMutation({
      variables: {
        input: {
          email,
          password,
        },
      },
    }).then((response) => {
      const {
        data: { sessionLogin },
      } = response;

      if (sessionLogin && sessionLogin.user) {
        Cookie.set(process.env.REACT_APP_COOKIE_NAME, sessionLogin.user.id);
        Cookie.set(
          `${process.env.REACT_APP_COOKIE_NAME}-token`,
          sessionLogin.token
        );

        userUpdateMutation({
          variables: {
            input: {
              id: sessionLogin.user.id,
              password: password1,
            },
          },
        }).then((response2) => {
          const {
            data: {
              userUpdate: { errors, success },
            },
          } = response2;

          if (success) {
            window.location.href = "/";
          } else {
            this.setState({
              error: errors[0].message,
              loading: false,
            });
          }
        });
      } else {
        this.setState({
          error: "Current password is incorrect.",
          loading: false,
        });
      }
    });
  };

  save = () => {
    const { email, password, password1, password2, token } = this.state;

    if (token) {
      if (!password1 || !password2) {
        this.handleChange("error", "Missing required fields.");
      } else if (password1 !== password2) {
        this.handleChange("error", "Passwords do not match.");
      } else if (password1.length < 6) {
        this.handleChange(
          "error",
          "Password does not meet minimum requirements."
        );
      } else {
        this.setState({ loading: true }, this.passwordReset);
      }
    } else {
      if (!email) {
        this.props.history.push("/error");
      }
      if (!password || !password1 || !password2) {
        this.handleChange("error", "Missing required fields.");
      } else if (password1 !== password2) {
        this.handleChange("error", "Passwords do not match.");
      } else if (password1.length < 6) {
        this.handleChange(
          "error",
          "Password does not meet minimum requirements."
        );
      } else {
        this.setState({ loading: true }, this.sessionLogin);
      }
    }
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <PasswordReset
        goToBaseRoute={this.goToBaseRoute}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(PasswordResetMutation, {
    name: "passwordResetMutation",
    options: (props) => ({ client: Window.client2 }),
  }),
  graphql(SessionLoginMutation, {
    name: "sessionLoginMutation",
    options: (props) => ({ client: window.client2 }),
  }),
  graphql(UserUpdateMutation, { name: "userUpdateMutation" })
)(PasswordResetContainer);
