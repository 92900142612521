import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose, set } from "lodash";

import Cookie from "js-cookie";

import UserQuery from "../../../graphql/queries/Document";
import DocumentCreateMutation from "../../../graphql/mutations/Document/Create";
import DocumentRemoveMutation from "../../../graphql/mutations/Document/Remove";
import DocumentRenewMutation from "../../../graphql/mutations/Document/Renew";
import DocumentUpdateMutation from "../../../graphql/mutations/Document/Update";

import License from "../../../components/Documents/License";

import LoadingPane from "../../../components/Shared/LoadingPane";
import moment from "moment";

class LicenseDocumentContainer extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { document },
      },
      mobile,
    } = props;

    this.state = {
      current: false,
      edit: document === "create",
      kind: "license",
      isModal: false,
      isModal2: false,
      loading: false,
      mobile,
      onload: document !== "create",
      user: props.user,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    const { mobile } = this.props;

    this.setup(prevProps.mobile !== mobile);
  }

  setup = (mobileDidChange) => {
    const {
      mobile,
      userQuery: { loading, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState({
        ...user.document,
        current: user.document.expiresOn ? false : true,
        mobile: mobileDidChange ? mobile : this.state.mobile,
        onload: false,
      });
    } else if (mobileDidChange) {
      this.handleChange("mobile", mobile);
    }
  };

  back = () => {
    this.props.history.goBack();
  };

  checkLicense = (document, success) => {
    if (success) {
      success();
    } else {
      this.back();
    }
  };

  documentRemove = (hide) => {
    const { documentRemoveMutation } = this.props;

    this.setState(
      {
        loading: true,
      },
      () => {
        documentRemoveMutation({
          variables: {
            input: {
              id: this.state.id,
            },
          },
        }).then((response) => {
          const { errors, success } = response.data.documentRemove;

          if (success) {
            setTimeout(() => {
              hide();

              setTimeout(() => this.back(), 450);
            }, 200);
          } else {
            this.handleChange("loading", false);

            window.alert(errors[0].message);
          }
        });
      }
    );
  };

  documentRenew = (hide) => {
    const { documentCreateMutation } = this.props;
    const {
      documentNumber,
      kind,
      issuingState,
      renewExpiresOn,
      renewFile,
      renewIssuedOn,
      title,
    } = this.state;

    if (moment(renewIssuedOn).isAfter(moment(renewExpiresOn))) {
      window.alert("Expiration date cannot occur before the issued date.");
    } else if (renewExpiresOn && renewIssuedOn) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          let data = {
            documentNumber,
            kind,
            expiresOn: renewExpiresOn,
            issuedOn: renewIssuedOn,
            issuingState,
            title,
            userId: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
          };

          if (renewFile) data.image = await this.saveAttachment(renewFile);

          documentCreateMutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const { errors, result, success } = response.data.documentCreate;

            if (success) {
              this.checkLicense(result, () => {
                hide();

                setTimeout(() => this.back(), 450);
              });
            } else {
              this.handleChange("loading", false);

              window.alert(errors[0].message);
            }
          });
        }
      );
    }
  };

  handleChange = (key, value) => {
    this.setState(set(this.state, key, value));
  };

  resetDocumentRenewal = () => {
    this.setState({
      isModal2: false,
      renewExpiresOn: null,
      renewFile: null,
      renewIssuedOn: null,
    });
  };

  save = () => {
    const { documentCreateMutation, documentUpdateMutation } = this.props;
    const {
      documentNumber,
      expiresOn,
      file,
      kind,
      id,
      issuedOn,
      issuingState,
      title,
    } = this.state;

    if (moment(issuedOn).isAfter(moment(expiresOn))) {
      window.alert("Expiration date cannot occur before the issued date.");
    } else if (
      title &&
      issuingState &&
      documentNumber &&
      issuedOn &&
      expiresOn
    ) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          let mutation = id ? documentUpdateMutation : documentCreateMutation,
            data = {
              documentNumber,
              kind,
              expiresOn,
              issuedOn,
              issuingState,
              title,
            };

          if (file) data.image = await this.saveAttachment(file);

          // document exists
          if (id) {
            data.id = id;
          } else {
            data.userId = Cookie.get(process.env.REACT_APP_COOKIE_NAME);
          }

          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const { errors, result, success } = response.data[key];

            if (success) {
              this.checkLicense(result);
            } else {
              this.handleChange("loading", false);

              window.alert(errors[0].message);
            }
          });
        }
      );
    }
  };

  saveAttachment = (file) => {
    return new Promise((success) => {
      let xhr = new XMLHttpRequest(),
        fileName = file.name,
        config = {
          type: `image/${fileName.split(".")[1]}`,
        },
        f = new File([file.base64], fileName, config),
        formData = new FormData();

      formData.append("file", f);

      xhr.open("POST", process.env.REACT_APP_ATTACHMENT_URL);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          success(JSON.parse(xhr.response));
        }
      };

      xhr.send(formData);
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <License
        {...this.props}
        back={this.back}
        documentRemove={this.documentRemove}
        documentRenew={this.documentRenew}
        handleChange={this.handleChange}
        resetDocumentRenewal={this.resetDocumentRenewal}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: "userQuery",
    options: (props) => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
        document: props.match.params.document,
      },
    }),
  }),
  graphql(DocumentCreateMutation, { name: "documentCreateMutation" }),
  graphql(DocumentRemoveMutation, { name: "documentRemoveMutation" }),
  graphql(DocumentRenewMutation, { name: "documentRenewMutation" }),
  graphql(DocumentUpdateMutation, { name: "documentUpdateMutation" })
)(LicenseDocumentContainer);
