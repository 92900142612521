import { gql } from 'apollo-boost'
 
const TestCompleteQuery = gql`
    query TestCompleteQuery($id: ID, $test: ID) {
        user(id: $id) {
            id
            testTaken(id: $test) {
                id
                status
                title
                test{
                    id
                    title
                    testtype
                }
            }
        }
    }
`
 
export default TestCompleteQuery