import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose, set } from "lodash";

import _ from "lodash";
import moment from "moment";
import Cookie from "js-cookie";

import UserQuery from "../../../graphql/queries/Document";
import DocumentCreateMutation from "../../../graphql/mutations/Document/Create";
import DocumentRemoveMutation from "../../../graphql/mutations/Document/Remove";
import DocumentRenewMutation from "../../../graphql/mutations/Document/Renew";
import DocumentUpdateMutation from "../../../graphql/mutations/Document/Update";

import Certification from "../../../components/Documents/Certification";

import LoadingPane from "../../../components/Shared/LoadingPane";

import STRINGS from "../../../localization";

class CertificationDocumentContainer extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { document },
      },
      mobile,
    } = props;

    this.state = {
      certifications: [],
      current: false,
      edit: document === "create",
      kind: "certification",
      isModal: false,
      isModal2: false,
      loading: false,
      mobile,
      onload: true,
      user: props.user,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    const { mobile } = this.props;

    this.setup(prevProps.mobile !== mobile);
  }

  setup = (mobileDidChange) => {
    const {
      mobile,
      userQuery: { loading, certifications, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      let document = user.document;

      this.setState({
        ...document,
        certifications: _.uniqBy(certifications, "name"),
        current: document && document.expiresOn ? false : true,
        mobile: mobileDidChange ? mobile : this.state.mobile,
        onload: false,
      });
    } else if (mobileDidChange) {
      this.handleChange("mobile", mobile);
    }
  };

  back = () => {
    this.props.history.goBack();
  };

  documentRemove = (hide) => {
    const { documentRemoveMutation } = this.props;

    this.setState(
      {
        loading: true,
      },
      () => {
        documentRemoveMutation({
          variables: {
            input: {
              id: this.state.id,
            },
          },
        }).then((response) => {
          const { errors, success } = response.data.documentRemove;

          if (success) {
            setTimeout(() => {
              hide();

              setTimeout(() => this.back(), 450);
            }, 200);
          } else {
            this.handleChange("loading", false);

            window.alert(errors[0].message);
          }
        });
      }
    );
  };

  documentRenew = (hide) => {
    const { documentCreateMutation } = this.props;
    const {
      documentNumber,
      kind,
      renewExpiresOn,
      renewFile,
      renewIssuedOn,
      title,
    } = this.state;

    if (moment(renewIssuedOn).isAfter(moment(renewExpiresOn))) {
      window.alert("Expiration date cannot occur before the issued date.");
    } else if (renewExpiresOn && renewIssuedOn) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          let data = {
            documentNumber: documentNumber || STRINGS.notProvided,
            kind,
            expiresOn: renewExpiresOn,
            issuedOn: renewIssuedOn,
            issuingState: "n/a",
            title,
            userId: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
          };

          if (renewFile) data.image = await this.saveAttachment(renewFile);

          documentCreateMutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const { errors, success } = response.data.documentCreate;

            if (success) {
              hide();

              setTimeout(() => this.back(), 450);
            } else {
              this.handleChange("loading", false);

              window.alert(errors[0].message);
            }
          });
        }
      );
    }
  };

  handleChange = (key, value) => {
    this.setState(set(this.state, key, value));
  };

  handleEdit = () => {
    this.setState({
      edit: true,
      title: "",
    });
  };

  resetDocumentRenewal = () => {
    this.setState({
      isModal2: false,
      renewExpiresOn: null,
      renewFile: null,
      renewIssuedOn: null,
    });
  };

  save = () => {
    const { documentCreateMutation, documentUpdateMutation } = this.props;
    const { documentNumber, expiresOn, file, kind, id, issuedOn, title } =
      this.state;

    if (moment(issuedOn).isAfter(moment(expiresOn))) {
      window.alert("Expiration date cannot occur before the issued date.");
    } else if (title && issuedOn && expiresOn) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          let mutation = id ? documentUpdateMutation : documentCreateMutation,
            data = {
              documentNumber: documentNumber || STRINGS.notProvided,
              kind,
              expiresOn,
              issuedOn,
              issuingState: "n/a",
              title,
            };

          if (file) data.image = await this.saveAttachment(file);

          // document exists
          if (id) {
            data.id = id;
          } else {
            data.userId = Cookie.get(process.env.REACT_APP_COOKIE_NAME);
          }

          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const { errors, success } = response.data[key];

            if (success) {
              this.back();
            } else {
              this.handleChange("loading", false);

              window.alert(errors[0].message);
            }
          });
        }
      );
    }
  };

  saveAttachment = (file) => {
    return new Promise((success) => {
      let xhr = new XMLHttpRequest(),
        fileName = file.name,
        config = {
          type: `image/${fileName.split(".")[1]}`,
        },
        f = new File([file.base64], fileName, config),
        formData = new FormData();

      formData.append("file", f);

      xhr.open("POST", process.env.REACT_APP_ATTACHMENT_URL);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          success(JSON.parse(xhr.response));
        }
      };

      xhr.send(formData);
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Certification
        {...this.props}
        back={this.back}
        documentRemove={this.documentRemove}
        documentRenew={this.documentRenew}
        handleChange={this.handleChange}
        handleEdit={this.handleEdit}
        resetDocumentRenewal={this.resetDocumentRenewal}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: "userQuery",
    options: (props) => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
        document: props.match.params.document,
      },
    }),
  }),
  graphql(DocumentCreateMutation, { name: "documentCreateMutation" }),
  graphql(DocumentRemoveMutation, { name: "documentRemoveMutation" }),
  graphql(DocumentRenewMutation, { name: "documentRenewMutation" }),
  graphql(DocumentUpdateMutation, { name: "documentUpdateMutation" })
)(CertificationDocumentContainer);
