import React from "react";

import _ from "lodash";
import moment from "moment";

import Bio from "./Bio";
import Navigation from "./Navigation";

import DocumentPane from "./panes/Document";
import ChangeRolePane from "../Account/panes/ChangeRole";

import WalletIcon from "../../theme/assets/una-wallet-icon-x2.png";
import TestIcon from "../../theme/assets/test-icon-2x.png";
import { useQuery } from "react-apollo";

import STRINGS from "../../localization";
import SubscriptionsList from "../../graphql/queries/Subscriptions";

import {
  jobTitles,
  nursysJurisdictions,
  positionTitles,
} from "../../utils/Constants";

const Credentials = (props) => {
  const { goToRoute, handleChange, handleSectionExpand, handleRetakeTest, refs, state } = props;
  const {
    checklistsTaken,
    coursesTaken,
    credentials,
    mobile,
    references,
    role,
    specialties,
    testsTaken,
    workExperiences,
    userSubscription,
  } = state;

  let tests = testsTaken;
  let checklists = checklistsTaken;
  let courses = coursesTaken;
  let subscriptions = userSubscription;

  const status_config = {
    active: "Active",
    inactive: "Inactive",
    ideal: "Ideal",
  };

  const goToTestRoute = () => {
    props.history.push({
      pathname: "/subscriptiondetails",
      userSubscription,
    });
  };

  const { data } = useQuery(SubscriptionsList, {
    variables: { status: ["active"] },
  });

  let subscription = null;
  if (data && data.subscriptions && data.subscriptions.edges.length > 0) {
    subscription = data.subscriptions.edges[0].node;
  }

  //  let tests = testsTaken.filter(o => (orgId === 1) ? o : o.orgId === orgId);
  //  let checklists = checklistsTaken.filter(o => (orgId === 1) ? o : o.orgId === orgId);
  //  let courses = coursesTaken.filter(o => (orgId === 1) ? o : o.orgId === orgId);

  return (
    <div className="credentials-view full-screen-view">
      <Navigation {...props} subscription={subscription} />

      <div className="content-frame scroll" style={{ top: mobile ? 48 : 60 }}>
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <Bio {...props} />

          <div className="sections-frame">
            <div className="header-frame">
              <div className="icon">
                <img src={TestIcon} alt="test-icon" />
              </div>

              <div className="header">UNA Test</div>
            </div>

            {/* subscriptions */}
            {/* <div
              className={
                role
                  ? role.org.modules.includes("tests")
                    ? "section-frame"
                    : "hidden"
                  : "section-frame"
              }
              ref={(ref) => (refs.section11 = ref)}
            >
              <div
                className="section-header-frame pointer"
                onClick={() => handleSectionExpand(refs.section11)}
              >
                <div className="section-label">
                  <b>Subscriptions</b> ({subscriptions.length})
                </div>

                <div className="flex-frame">
                  <div className="arrow-icon">
                    <img
                      className="arrow-img"
                      src={require("../../theme/assets/up-arrow-icon-2x.png")}
                      alt="arrow-icon"
                    />
                  </div>
                </div>
              </div>

              <ul className="section-list-view">
                {subscriptions.map((subs) => {
                  const {
                    status,
                    availedCredit,
                    subscriptionDate,
                    usedCredit,
                    __typename,
                    subscription,
                  } = subs;

                  return (
                    <li className="section-cell">
                      <div className="section-left-frame">
                        <div
                          className="section-label bold pointer"
                          onClick={() => goToTestRoute()}
                        >
                          {subscription.title} &nbsp;
                          {status === "active" ? (
                            ""
                          ) : (
                            <span style={{ color: "#ce1818" }}>
                              {availedCredit}
                            </span>
                          )}
                        </div>

                        <div className="section-label grey">
                          Valid till{" "}
                          {moment(subscriptionDate).format("MMM D, YYYY")}
                        </div>
                      </div>

                      <button
                        className="continue-button bg-una"
                        // onClick={() => goToRoute(`/tests/${id}/start`)}
                      >
                        {status_config[status] || status}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div> */}

            {/* tests */}
            <div
              className={
                role
                  ? role.org.modules.includes("tests")
                    ? "section-frame"
                    : "hidden"
                  : "section-frame"
              }
              ref={(ref) => (refs.section1 = ref)}
            >
              <div
                className="section-header-frame pointer"
                onClick={() => handleSectionExpand(refs.section1)}
              >
                <div className="section-label">
                  <b>{STRINGS.tests}</b> ({tests.length})
                </div>

                <div className="flex-frame">
                  <div className="arrow-icon">
                    <img
                      className="arrow-img"
                      src={require("../../theme/assets/up-arrow-icon-2x.png")}
                      alt="arrow-icon"
                    />
                  </div>
                </div>
              </div>

              <ul className="section-list-view">
                {tests.map((test) => {
                  const { endedAt, id, passed, score, status, title } = test;

                  let unfinished = status === "untaken" || status === "started",
                    formattedStatus = {
                      untaken: "Invitation",
                      started: "In Progress",
                    };

                  return unfinished ? (
                    <li className="section-cell" key={id}>
                      <div className="section-left-frame">
                        <div className="section-label bold">{title}</div>
                        <div className="section-label grey">
                          {formattedStatus[status]}
                        </div>
                      </div>

                      <button
                        className="continue-button bg-una"
                        onClick={() => goToRoute(`/tests/${id}/start`)}
                      >
                        {status === "untaken" ? "Begin Test" : "Continue"}
                      </button>
                    </li>
                  ) : (


                    <li
                      key={id}
                      className="section-cell"
                    >
                      <div className="section-left-frame pointer">
                        <div className="section-label bold" onClick={() => goToRoute(`/tests/${id}`)} >{title}</div>
                        <div className="score-frame">
                          <div className="section-label">{`${score}% -`}</div>
                          <ScoreLabel score={score} passed={passed} />
                        </div>
                      </div>

                      <div className="section-label date">
                        {moment(endedAt).format("MMM D, YYYY")}
                      </div>
                      {
                        true && <button // true happens when the org kind is 'ecp_job'
                          className="continue-button"
                          style={{ color: "#EB903D" }}
                          onClick={() => handleRetakeTest(id)}
                        >
                          Retake Test
                        </button>
                      }
                    </li>

                  );
                })}
              </ul>
            </div>

            {/* checklists */}
            <div
              className={
                role
                  ? role.org.modules.includes("checklists")
                    ? "section-frame"
                    : "hidden"
                  : "section-frame"
              }
              ref={(ref) => (refs.section2 = ref)}
            >
              <div
                className="section-header-frame pointer"
                onClick={() => handleSectionExpand(refs.section2)}
              >
                <div className="section-label">
                  <b>{STRINGS.skillsChecklists}</b> ({checklists.length})
                </div>

                <div className="flex-frame">
                  <div className="arrow-icon">
                    <img
                      className="arrow-img"
                      src={require("../../theme/assets/up-arrow-icon-2x.png")}
                      alt="arrow-icon"
                    />
                  </div>
                </div>
              </div>

              <ul className="section-list-view">
                {checklists.map((checklistsTaken) => {
                  const { answers, endedAt, id, status, title } =
                    checklistsTaken;

                  // eslint-disable-next-line
                  let score = 0,
                    unfinished = status === "untaken" || status === "started",
                    formattedStatus = {
                      untaken: "Invitation",
                      started: "In Progress",
                    };

                  answers.forEach((answer) => {
                    score += parseInt(answer.value);
                  });

                  let scoreAvg = parseFloat(score / answers.length);

                  return unfinished ? (
                    <li className="section-cell" key={id}>
                      <div className="section-left-frame">
                        <div className="section-label bold">{title}</div>

                        <div className="section-label grey">
                          {formattedStatus[status]}
                        </div>
                      </div>

                      <button
                        className="continue-button bg-una"
                        onClick={() => goToRoute(`/checklists/${id}/start`)}
                      >
                        {status === "untaken" ? "Begin" : "Continue"}
                      </button>
                    </li>
                  ) : (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={() => goToRoute(`/checklists/${id}`)}
                    >
                      <div className="section-left-frame" >
                        <div className="section-label bold" >{title}</div>

                        <div className="score-frame">
                          <div className="section-label">
                            {scoreAvg.toFixed(1)}
                          </div>
                        </div>
                      </div>

                      <div className="section-label date">
                        {moment(endedAt).format("MMM D, YYYY")}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* mandatories */}
            <div
              className={
                role
                  ? role.org.modules.includes("mandatories")
                    ? "section-frame"
                    : "hidden"
                  : "section-frame"
              }
              ref={(ref) => (refs.section3 = ref)}
            >
              <div
                className="section-header-frame pointer"
                onClick={() => handleSectionExpand(refs.section3)}
              >
                <div className="section-label">
                  <b>{STRINGS.courses}</b> ({courses.length})
                </div>

                <div className="flex-frame">
                  <div className="arrow-icon">
                    <img
                      className="arrow-img"
                      src={require("../../theme/assets/up-arrow-icon-2x.png")}
                      alt="arrow-icon"
                    />
                  </div>
                </div>
              </div>

              <ul className="section-list-view">
                {courses.map((course) => {
                  const { endedAt, id, passed, score, status, title } = course;

                  let unfinished = status === "untaken" || status === "started",
                    formattedStatus = {
                      untaken: "Invitation",
                      started: "In Progress",
                    };

                  return unfinished ? (
                    <li className="section-cell" key={id}>
                      <div className="section-left-frame">
                        <div className="section-label bold">{title}</div>

                        <div className="section-label grey">
                          {formattedStatus[status]}
                        </div>
                      </div>

                      <button
                        className="continue-button bg-una"
                        onClick={() => goToRoute(`/courses/${id}/chapters`)}
                      >
                        {status === "untaken" ? "Begin" : "Continue"}
                      </button>
                    </li>
                  ) : (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={() => goToRoute(`/courses/${id}`)}
                    >
                      <div className="section-left-frame">
                        <div className="section-label bold" >{title}</div>

                        <div className="score-frame">
                          <div className="section-label">{`${score}% -`}</div>
                          <ScoreLabel score={score} passed={passed} />
                        </div>
                      </div>

                      <div className="section-label date">
                        {moment(endedAt).format("MMM D, YYYY")}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* credentials */}
            <div
              className={
                role
                  ? role.org.modules.includes("credentials")
                    ? "credentials-frame"
                    : "hidden"
                  : "credentials-frame"
              }
            >
              <div className="header-frame">
                <div className="header-frame">
                  <div className="icon">
                    <img src={WalletIcon} alt="wallet-icon" />
                  </div>

                  <div className="header">UNA Wallet</div>
                </div>
              </div>

              {/* specialties */}
              <div
                className="section-frame"
                ref={(ref) => (refs.section6 = ref)}
              >
                <div
                  className="section-header-frame pointer"
                  onClick={(e) => {
                    let className = e.target.className;

                    if (className === "add-img") {
                      goToRoute("/specialties/create");
                    } else {
                      handleSectionExpand(refs.section6);
                    }
                  }}
                >
                  <div className="section-label">
                    <b>{STRINGS.specialties}</b> ({specialties.length})
                  </div>

                  <div className="flex-frame">
                    <div className="add-icon">
                      <img
                        className="add-img"
                        src={require("../../theme/assets/add-icon-2x-red.png")}
                        alt="add-icon"
                      />
                    </div>

                    <div className="arrow-icon">
                      <img
                        className="arrow-img"
                        src={require("../../theme/assets/up-arrow-icon-2x.png")}
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                </div>

                <ul className="section-list-view">
                  {specialties.map((specialty) => {
                    const { id, isPrimary, name, startedOn } = specialty;

                    let diff = moment().diff(moment(startedOn).startOf("day")),
                      duration = moment.duration(diff),
                      years = duration.years(),
                      months = duration.months(),
                      days = Math.floor(duration.asDays());

                    return (
                      <li
                        className="section-cell pointer"
                        key={id}
                        onClick={() => goToRoute(`/specialties/${id}`)}
                      >
                        <div className="section-left-frame">
                          <div className="section-label bold">{name}</div>

                          <div className="section-label">
                            {years === 0 && months === 0
                              ? `${days} ${days > 1 ? STRINGS.days : STRINGS.day
                              }`
                              : `${years > 0
                                ? `${years} ${years > 1 ? STRINGS.years : STRINGS.year
                                }`
                                : ""
                              }${months > 0
                                ? ` ${months} ${months > 1
                                  ? STRINGS.months
                                  : STRINGS.month
                                }`
                                : ""
                              }`}
                          </div>
                        </div>

                        <div className="section-label date aqua">
                          {isPrimary ? STRINGS.primary : ""}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* work experiences */}
              <div
                className="section-frame"
                ref={(ref) => (refs.section5 = ref)}
              >
                <div
                  className="section-header-frame pointer"
                  onClick={(e) => {
                    let className = e.target.className;

                    if (className === "add-img") {
                      goToRoute("/documents/work_experiences/create");
                    } else {
                      handleSectionExpand(refs.section5);
                    }
                  }}
                >
                  <div className="section-label capitalize">
                    <b>{STRINGS.workExperience}</b> ({workExperiences.length})
                  </div>

                  <div className="flex-frame">
                    <div className="add-icon">
                      <img
                        className="add-img"
                        src={require("../../theme/assets/add-icon-2x-red.png")}
                        alt="add-icon"
                      />
                    </div>

                    <div className="arrow-icon">
                      <img
                        className="arrow-img"
                        src={require("../../theme/assets/up-arrow-icon-2x.png")}
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                </div>

                <ul className="section-list-view">
                  {_.orderBy(
                    workExperiences,
                    ["endedOn", "startedOn"],
                    ["desc", "desc"]
                  ).map((workExperience) => {
                    const {
                      city,
                      endedOn,
                      id,
                      name,
                      positionTitle,
                      startedOn,
                      state,
                    } = workExperience;

                    let position = positionTitles.find(
                      (p) => p.id === positionTitle
                    );

                    return (
                      <li
                        className="section-cell pointer"
                        key={id}
                        onClick={() =>
                          goToRoute(`/documents/work_experiences/${id}`)
                        }
                      >
                        <div className="section-left-frame">
                          <div className="section-label bold">{name}</div>

                          <div className="section-label">{`${position ? `${position.value} | ` : ""
                            }${city}, ${state}`}</div>
                        </div>

                        <div className="section-label date-range">{`${moment(
                          startedOn
                        ).format("MMM YYYY")} - ${endedOn
                          ? moment(endedOn).format("MMM YYYY")
                          : STRINGS.present
                          }`}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* education */}
              <div
                className="section-frame"
                ref={(ref) => (refs.section4 = ref)}
              >
                <div
                  className="section-header-frame pointer"
                  onClick={(e) => {
                    let className = e.target.className;

                    if (className === "add-img") {
                      goToRoute("/documents/education/create");
                    } else {
                      handleSectionExpand(refs.section4);
                    }
                  }}
                >
                  <div className="section-label">
                    <b>{STRINGS.education}</b> ({credentials.education.length})
                  </div>

                  <div className="flex-frame">
                    <div className="add-icon">
                      <img
                        className="add-img"
                        src={require("../../theme/assets/add-icon-2x-red.png")}
                        alt="add-icon"
                      />
                    </div>

                    <div className="arrow-icon">
                      <img
                        className="arrow-img"
                        src={require("../../theme/assets/up-arrow-icon-2x.png")}
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                </div>

                <ul className="section-list-view">
                  {credentials.education.map((document) => {
                    const {
                      kind,
                      id,
                      issuerLocation,
                      issuerName,
                      issuedOn,
                      issuingState,
                      title,
                    } = document;

                    let degree = {
                      associate: STRINGS.associateDegree,
                      bachelor: STRINGS.bachelorDegree,
                      doctor: STRINGS.doctoralDegree,
                      master: STRINGS.masterDegree,
                    };

                    return (
                      <li
                        className="section-cell pointer"
                        key={id}
                        onClick={() => goToRoute(`/documents/${kind}/${id}`)}
                      >
                        <div className="section-left-frame">
                          <div className="section-label bold">{issuerName}</div>

                          <div className="section-label">{`${degree[title]} ${issuerLocation && issuerLocation
                            ? `| ${issuerLocation}, ${issuingState}`
                            : ""
                            }`}</div>
                        </div>

                        <div className="section-label date">
                          {moment(issuedOn).format("MMM YYYY")}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* licenses */}
              <div
                className="section-frame"
                ref={(ref) => (refs.section7 = ref)}
              >
                <div
                  className="section-header-frame pointer"
                  onClick={(e) => {
                    let className = e.target.className;

                    if (className === "add-img") {
                      goToRoute("/documents/licenses/create");
                    } else {
                      handleSectionExpand(refs.section7);
                    }
                  }}
                >
                  <div className="section-label capitalize">
                    <b>{`${STRINGS.license}s`}</b> ({credentials.license.length}
                    )
                  </div>

                  <div className="flex-frame">
                    <div className="add-icon">
                      <img
                        className="add-img"
                        src={require("../../theme/assets/add-icon-2x-red.png")}
                        alt="add-icon"
                      />
                    </div>

                    <div className="arrow-icon">
                      <img
                        className="arrow-img"
                        src={require("../../theme/assets/up-arrow-icon-2x.png")}
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                </div>

                <ul className="section-list-view">
                  {_.orderBy(credentials.license, ["expiresOn"], ["desc"]).map(
                    (document) => {
                      const {
                        documentNumber,
                        expiresOn,
                        id,
                        issuingState,
                        isVerified,
                        kind,
                        status,
                        title,
                      } = document;

                      let state = nursysJurisdictions.find(
                        (s) => s.abbreviation === issuingState
                      );

                      return (
                        <li
                          className="section-cell pointer"
                          key={id}
                          onClick={() => goToRoute(`/documents/${kind}s/${id}`)}
                        >
                          <div className="section-left-frame ellipsis">
                            <div className="section-label bold">{title}</div>

                            <div className="flex-frame">
                              <div
                                className="section-label"
                                style={{ marginRight: isVerified ? 0 : 3 }}
                              >{`#${documentNumber} ${isVerified ? "- " : ""
                                }`}</div>

                              <div
                                className={
                                  isVerified
                                    ? "section-label aqua capitalize"
                                    : "hidden"
                                }
                                style={{ margin: "0px 3px" }}
                              >
                                {STRINGS.verified}
                              </div>

                              <div className="section-label ellipsis">{`| ${state ? state.name : STRINGS.noIssuingState
                                }`}</div>
                            </div>
                          </div>

                          <div className="flex-frame date-range">
                            <div
                              className={`section-label expired ${status === "active" ? "" : "una"
                                }`}
                            >
                              {status === "active"
                                ? STRINGS.expires
                                : STRINGS.expired}
                            </div>
                            <div className="section-label">{`- ${moment(
                              expiresOn
                            ).format("MMM DD, YYYY")}`}</div>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>

              {/* certifications  */}
              <div
                className="section-frame"
                ref={(ref) => (refs.section8 = ref)}
              >
                <div
                  className="section-header-frame pointer"
                  onClick={(e) => {
                    let className = e.target.className;

                    if (className === "add-img") {
                      goToRoute("/documents/certifications/create");
                    } else {
                      handleSectionExpand(refs.section8);
                    }
                  }}
                >
                  <div className="section-label capitalize">
                    <b>{`${STRINGS.certification}s`}</b> (
                    {credentials.certification.length})
                  </div>

                  <div className="flex-frame">
                    <div className="add-icon">
                      <img
                        className="add-img"
                        src={require("../../theme/assets/add-icon-2x-red.png")}
                        alt="add-icon"
                      />
                    </div>

                    <div className="arrow-icon">
                      <img
                        className="arrow-img"
                        src={require("../../theme/assets/up-arrow-icon-2x.png")}
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                </div>

                <ul className="section-list-view">
                  {_.orderBy(
                    credentials.certification,
                    ["expiresOn"],
                    ["desc"]
                  ).map((document) => {
                    const {
                      documentNumber,
                      expiresOn,
                      id,
                      kind,
                      status,
                      title,
                    } = document;

                    return (
                      <li
                        className="section-cell pointer"
                        key={id}
                        onClick={() => goToRoute(`/documents/${kind}s/${id}`)}
                      >
                        <div className="section-left-frame">
                          <div className="section-label bold">{title}</div>

                          {documentNumber === STRINGS.notProvided ? null : (
                            <div className="section-label">
                              {documentNumber}
                            </div>
                          )}
                        </div>

                        <div className="flex-frame date-range">
                          <div
                            className={`section-label expired ${status === "active" ? "" : "una"
                              }`}
                          >
                            {status === "active"
                              ? STRINGS.expires
                              : STRINGS.expired}
                          </div>
                          <div className="section-label">{`- ${moment(
                            expiresOn
                          ).format("MMM DD, YYYY")}`}</div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* references */}
              <div
                className="section-frame"
                ref={(ref) => (refs.section9 = ref)}
              >
                <div
                  className="section-header-frame pointer"
                  onClick={(e) => {
                    let className = e.target.className;

                    if (className === "add-img") {
                      goToRoute("/documents/references/create");
                    } else {
                      handleSectionExpand(refs.section9);
                    }
                  }}
                >
                  <div className="section-label capitalize">
                    <b>{`${STRINGS.reference}s`}</b> ({references.length})
                  </div>

                  <div className="flex-frame">
                    <div className="add-icon">
                      <img
                        className="add-img"
                        src={require("../../theme/assets/add-icon-2x-red.png")}
                        alt="add-icon"
                      />
                    </div>

                    <div className="arrow-icon">
                      <img
                        className="arrow-img"
                        src={require("../../theme/assets/up-arrow-icon-2x.png")}
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                </div>

                <ul className="section-list-view">
                  {_.orderBy(
                    references,
                    ["completedAtIso", "createdAtIso"],
                    ["desc", "desc"]
                  ).map((document) => {
                    const {
                      completedAtIso,
                      createdAtIso,
                      firstName,
                      lastName,
                      id,
                      organization,
                      status,
                      title,
                    } = document;

                    let sublabel = "",
                      statuses = {
                        completed: "green",
                        pending: "grey",
                        invited: "una",
                      },
                      jobTitle = jobTitles.find((o) => o.id === title);

                    if (organization && jobTitle) {
                      sublabel = `${organization} | ${jobTitle.value}`;
                    } else if (organization) {
                      sublabel = organization;
                    } else if (jobTitle) {
                      sublabel = jobTitle.value;
                    }

                    return (
                      <li
                        className="section-cell pointer"
                        key={id}
                        onClick={() => goToRoute(`/documents/references/${id}`)}
                      >
                        <div className="section-left-frame">
                          <div className="section-label bold">{`${firstName} ${lastName}`}</div>

                          <div
                            className={`section-label capitalize ${organization || title ? "" : "hint italic"
                              } `}
                          >
                            {sublabel}
                          </div>
                        </div>

                        <div className="flex-frame date-range">
                          <div
                            className={`section-label capitalize ${statuses[status]}`}
                          >
                            {status}
                          </div>
                          <div
                            className="section-label"
                            style={{ marginLeft: 3 }}
                          >{`- ${moment(completedAtIso || createdAtIso).format(
                            "MMM DD, YYYY"
                          )}`}</div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* documents */}
              <div
                className="section-frame"
                ref={(ref) => (refs.section10 = ref)}
              >
                <div
                  className="section-header-frame pointer"
                  onClick={(e) => {
                    let className = e.target.className;

                    if (className === "add-img") {
                      handleChange("isModal", true);
                    } else {
                      handleSectionExpand(refs.section10);
                    }
                  }}
                >
                  <div className="section-label capitalize">
                    <b>{`${STRINGS.document}s`}</b> ({credentials.misc.length})
                  </div>

                  <div className="flex-frame">
                    <div className="add-icon">
                      <img
                        className="add-img"
                        src={require("../../theme/assets/add-icon-2x-red.png")}
                        alt="add-icon"
                      />
                    </div>

                    <div className="arrow-icon">
                      <img
                        className="arrow-img"
                        src={require("../../theme/assets/up-arrow-icon-2x.png")}
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                </div>
                <ul className="section-list-view">
                  {_.orderBy(credentials.misc, ["title"], ["asc"]).map(
                    (document) => {
                      const {
                        documentNumber,
                        expiresOn,
                        imageUrl,
                        imageBackUrl,
                        id,
                        issuedOn,
                        kind,
                        title,
                        verificationData,
                      } = document;

                      let images = [imageUrl, imageBackUrl],
                        extraImages = verificationData
                          ? Object.keys(verificationData).filter((key) =>
                            key.includes("image")
                          )
                          : [],
                        arr = images.filter((img) => img).concat(extraImages),
                        expiredDocs = ["drivers_license", "other", "ppd"],
                        date =
                          expiredDocs.includes(kind) && expiresOn
                            ? expiresOn
                            : issuedOn;

                      return (
                        <li
                          className="section-cell pointer"
                          key={id}
                          onClick={() => goToRoute(`/documents/${kind}s/${id}`)}
                        >
                          <div className="section-left-frame">
                            {kind === "other" ? (
                              <div className="section-label bold">{`${_.capitalize(
                                STRINGS.other
                              )} - ${title}`}</div>
                            ) : (
                              <div className="section-label bold">{title}</div>
                            )}

                            <div className="section-label capitalize">
                              {kind === "titer"
                                ? documentNumber
                                : `${arr.length} ${arr.length > 1
                                  ? `${STRINGS.photo}s`
                                  : STRINGS.photo
                                }`}
                            </div>
                          </div>

                          <div className="flex-frame date-range">
                            <div className="section-label">
                              {moment(date).format("MMM DD, YYYY")}
                            </div>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>

            <DocumentPane {...props} />
            <ChangeRolePane {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ScoreLabel = (props) => {
  const { passed, score } = props;

  let label,
    color,
    isChecklist = passed === undefined;

  if (isChecklist) {
    if (score > 50 && score <= 80) {
      color = "#24A4DB";
      label = "Good";
    } else if (score > 80) {
      color = "#4DD865";
      label = "Excellent";
    } else {
      color = "#EB903D";
      label = "Fair";
    }
  } else {
    color = passed ? "#3EC253" : "#EB903D";
    label = passed ? "Pass" : "Did Not Pass";
  }

  return (
    <div className="section-label" style={{ color, marginLeft: 4 }}>
      {label}
    </div>
  );
};

export default Credentials;
