import React from "react";
import { graphql } from "react-apollo";

import { flowRight as compose } from "lodash";
import Cookie from "js-cookie";

import UserQuery from "../../graphql/queries/Course/Start";

import UserCourseFinishMutation from "../../graphql/mutations/User/Course/Finish";
import UserCourseStartMutation from "../../graphql/mutations/User/Course/Start";
import UserCourseAnswerCreateMutation from "../../graphql/mutations/User/Course/Answer/Create";
import UserCourseAnswerUpdateMutation from "../../graphql/mutations/User/Course/Answer/Update";

import CourseStart from "../../components/Course/Start";

import LoadingPane from "../../components/Shared/LoadingPane";

var timer;

class CourseStartContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModal: false,
      mobile: window.innerWidth < 1000,
      onload: true,
    };
  }

  componentDidMount() {
    const {
      userQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(this.setup);
    } else {
      this.setup();
    }

    timer = setInterval(this.handleSecs, 1000);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    clearInterval(timer);
    window.removeEventListener("resize", this.handleResize);
  }

  setup = () => {
    const {
      match,
      userQuery: { loading, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      let courseTaken = user.courseTaken;

      if (courseTaken.status === "finished") {
        this.goToRoute(`/courses/${match.params.course}/complete`);
      } else if (courseTaken.status === "untaken") {
        this.startCourse(courseTaken);
      } else {
        this.setupCourse(courseTaken);
      }
    }
  };

  setupChapter = (chapter) => {
    let elem = document.createElement("div");

    elem.innerHTML = chapter.contentFormatted.replace(/<img[^>]*>/g, (img) =>
      img.replace(/img src/g, 'a target="_blank" href').replace("/>", "></a>")
    );

    for (var i = 0; i < elem.getElementsByTagName("a").length; i++) {
      let node = elem.getElementsByTagName("a")[i];
      const href = node.getAttribute("href");
      if (href.endsWith(".png") || href.endsWith(".jpg")) {
        const img = document.createElement("img");

        img.src = href;
        img.setAttribute("width", "100%");

        node.appendChild(img);
      }
    }

    return elem.outerHTML;
  };

  setupCourse = (courseTaken) => {
    let questions = courseTaken.course.chapters.map((c) => c.questions).flat(),
      answerHash = {};

    courseTaken.answers.forEach((answer) => {
      answerHash[answer.question.id] = answer;
    });

    let index = questions.findIndex((q) => !answerHash[q.id]);

    this.setState({
      ...courseTaken,
      answers: answerHash,
      index: index >= 0 ? index : 0,
      onload: false,
      questions: courseTaken.course.chapters.map((c) => c.questions).flat(),
      secs: 0,
    });
  };

  goToCourseRoute = () => {
    const { history, match } = this.props;

    history.push(`/courses/${match.params.course}/chapters`);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleQuestion = (forward) => {
    const {
      history,
      match,
      userCourseAnswerCreateMutation,
      userCourseAnswerUpdateMutation,
      userCourseFinishMutation,
    } = this.props;
    const { answers, id, index, questions, secs, selected } = this.state;

    if (selected) {
      let question = questions[index],
        nextIndex = forward ? index + 1 : index - 1;

      this.setState(
        {
          loading: true,
        },
        () => {
          let existing = answers[question.id],
            mutation = existing
              ? userCourseAnswerUpdateMutation
              : userCourseAnswerCreateMutation,
            data = existing
              ? {
                  id: existing.id,
                  value: selected,
                }
              : {
                  userCourseId: id,
                  questionId: question.id,
                  value: selected,
                  secs,
                };

          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const { errors, success } = response.data[key];

            if (success) {
              // questions are done - mark course as finished
              if (nextIndex >= questions.length) {
                userCourseFinishMutation({
                  variables: {
                    input: {
                      id,
                    },
                  },
                }).then((response) => {
                  const {
                    data: {
                      userCourseFinish: { errors, success },
                    },
                  } = response;

                  if (success) {
                    history.push(`/courses/${match.params.course}/complete`);
                  } else {
                    window.alert(errors[0].message);
                  }
                });
              } else {
                this.setState({
                  index: nextIndex,
                  loading: false,
                  secs: 0,
                  selected: null,
                });
              }
            } else {
              this.handleChange("loading", false);

              window.alert(errors[0].message);
            }
          });
        }
      );
    } else {
      window.alert("Answer is required.");
    }
  };

  handleResize = () => {
    this.handleChange("mobile", window.innerWidth < 1000);
  };

  handleSecs = () => {
    this.handleChange("secs", this.state.secs + 1);
  };

  startCourse = (courseTaken) => {
    const { userCourseStartMutation } = this.props;

    userCourseStartMutation({
      variables: {
        input: {
          id: courseTaken.id,
        },
      },
    }).then((response) => {
      const {
        data: {
          userCourseStart: { errors, success },
        },
      } = response;

      if (success) {
        this.setupCourse(courseTaken);
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  render() {
    const { onload } = this.state;

    return onload ? (
      <LoadingPane />
    ) : (
      <CourseStart
        goToCourseRoute={this.goToCourseRoute}
        handleChange={this.handleChange}
        handleQuestion={this.handleQuestion}
        setupChapter={this.setupChapter}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: "userQuery",
    options: (props) => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
        course: props.match.params.course,
      },
    }),
  }),
  graphql(UserCourseFinishMutation, { name: "userCourseFinishMutation" }),
  graphql(UserCourseStartMutation, { name: "userCourseStartMutation" }),
  graphql(UserCourseAnswerCreateMutation, {
    name: "userCourseAnswerCreateMutation",
  }),
  graphql(UserCourseAnswerUpdateMutation, {
    name: "userCourseAnswerUpdateMutation",
  })
)(CourseStartContainer);
