import { gql } from 'apollo-boost'

const TestQuery = gql`
    query TestQuery($id: ID, $test: ID) {
        user(id: $id) {
            id
            testTaken(id: $test) {
                id
                answers {
                    id
                    question {
                        id
                    }
                    isCorrect
                }
                endedAt
                passed
                score
                status
                test {
                    id
                    testtype
                    categories {
                        id
                        rank
                        questions {
                            id
                            title
                        }
                        title
                    }
                    desc
                }
                title
            }
        }
    }
`

export default TestQuery