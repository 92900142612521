import React from 'react'

import GenericArtwork from '../../theme/assets/una-course-icon-2x.png'
import GenericArtworkMobile from '../../theme/assets/una-course-icon.png'

import STRINGS from '../../localization'

const CourseChapters = (props) => {
    const { goToChapterRoute, goToCourseStartRoute, goToCredentialsRoute, state } = props
    const { course, mobile } = state
    const { artworkUrl, chapters, desc, title } = course

    let genericArtwork = mobile ? GenericArtworkMobile : GenericArtwork
    
    return (
        <div className='course-chapters-view'>
            {mobile ?
                <div className='toolbar mobile'>
                    <div className='mobile-back-button' onClick={goToCredentialsRoute}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-back-arrow-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>

                    <div className='page-header-frame'>
                        <div className='page-header ellipsis'>{title}</div>
                        <div className='page-subheader ellipsis'>{`${STRINGS.course} - ${chapters.length} Chapters`}</div>
                    </div>

                    <button className='save-button' onClick={goToCourseStartRoute}>Take Test</button>
                </div>
                :
                <div className='toolbar'>
                    <button className='back-button' onClick={goToCredentialsRoute}>Back</button>

                    <div className='page-header ellipsis'><b>{title}</b>{` - ${STRINGS.course} - ${chapters.length} Chapters`}</div>

                    <button className='save-button' onClick={goToCourseStartRoute}>Take The Test</button>
                </div>
            }

            <div className='content-frame scroll'>
                <div className={mobile ? 'banner-frame mobile' : 'banner-frame'}>
                    <div className='banner'>
                        <img src={artworkUrl || genericArtwork} alt='banner-icon' />
                    </div>
                </div>

                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className={desc ? 'desc' : 'desc hint'}>{desc || `${STRINGS.course} has no description.`}</div>

                    <ul className='chapters-list-view'>
                        {chapters.map(chapter => {
                            const { id, title } = chapter

                            return (
                                <li className='chapter-cell pointer' key={id} onClick={() => goToChapterRoute(chapter)}>
                                    <div className='chapter-title ellipsis'>{title}</div>

                                    <div className='arrow-detail-icon'>
                                        <img src={require('../../theme/assets/arrow-detail-icon-2x.png')} alt='arrow-detail-icon' />
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CourseChapters