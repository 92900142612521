import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose, set } from "lodash";

import axios from "axios";
import Cookie from "js-cookie";
import moment from "moment";

import UserQuery from "../../../graphql/queries/Document/Reference";
import ReferenceCreateMutation from "../../../graphql/mutations/Reference/Create";
import ReferenceInviteMutation from "../../../graphql/mutations/Reference/Invite";
import ReferenceRemoveMutation from "../../../graphql/mutations/Reference/Remove";
import ReferenceUpdateMutation from "../../../graphql/mutations/Reference/Update";

import Reference from "../../../components/Documents/Reference";

import LoadingPane from "../../../components/Shared/LoadingPane";

class ReferenceDocumentContainer extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { document },
      },
      mobile,
    } = props;

    this.state = {
      current: false,
      edit: document === "create",
      isModal: false,
      loading: false,
      mobile,
      onload: document !== "create",
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    const { mobile } = this.props;

    this.setup(prevProps.mobile !== mobile);
  }

  setup = (mobileDidChange) => {
    const {
      mobile,
      userQuery: { loading, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState({
        ...user.reference,
        current: user.reference.endDate ? false : true,
        mobile: mobileDidChange ? mobile : this.state.mobile,
        onload: false,
      });
    } else if (mobileDidChange) {
      this.handleChange("mobile", mobile);
    }
  };

  back = () => {
    this.props.history.goBack();
  };

  documentRemove = (hide) => {
    const { referenceRemoveMutation } = this.props;

    this.setState(
      {
        loading: true,
      },
      () => {
        referenceRemoveMutation({
          variables: {
            input: {
              id: this.state.id,
            },
          },
        }).then((response) => {
          const { errors, success } = response.data.referenceRemove;

          if (success) {
            setTimeout(() => {
              hide();

              setTimeout(() => this.back(), 450);
            }, 200);
          } else {
            this.handleChange("loading", false);

            window.alert(errors[0].message);
          }
        });
      }
    );
  };

  handleChange = (key, value) => {
    this.setState(set(this.state, key, value));
  };

  save = () => {
    const { referenceCreateMutation, referenceUpdateMutation } = this.props;
    const {
      current,
      email,
      endDate,
      firstName,
      id,
      invitationNote,
      lastName,
      organization,
      phone,
      startDate,
      title,
    } = this.state;

    if (moment(startDate).isAfter(moment(endDate)) && !current) {
      window.alert("Expiration date cannot occur before the issued date.");
    } else if (firstName && lastName && email) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          let mutation = id ? referenceUpdateMutation : referenceCreateMutation,
            data = {
              email,
              firstName,
              invitationNote,
              lastName,
              organization,
              phone,
              title,
              startDate,
              endDate: current ? moment().format("YYYY-MM-DD") : endDate,
            };

          // document exists
          if (id) {
            data.id = id;
          } else {
            data.userId = Cookie.get(process.env.REACT_APP_COOKIE_NAME);
          }

          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const { errors, result, success } = response.data[key];

            if (success) {
              if (id) this.back();
              else this.sendInvite(result);
            } else {
              this.handleChange("loading", false);

              window.alert(errors[0].message);
            }
          });
        }
      );
    }
  };

  sendInvite = (reference) => {
    const { referenceInviteMutation } = this.props;

    referenceInviteMutation({
      variables: {
        input: {
          id: reference.id,
        },
      },
    }).then((response) => {
      const { errors, success, result } = response.data.referenceInvite;

      if (success) {
        reference.token = result.token;
        this.sendEmail(reference);
      } else {
        this.handleChange("loading", false);

        window.alert(errors[0].message);
      }
    });
  };

  sendEmail = (reference) => {
    const { firstName, lastName } = this.props.user;
    const { email, firstName: referenceFirstName, invitationNote } = this.state;
    const referenceToken =
      reference.token || process.env.REACT_APP_REFERENCE_TOKEN;

    let url = `${process.env.REACT_APP_PROXY_URL}/send`,
      data = {
        dynamicTemplateData: {
          referenceFirstName,
          firstName,
          lastName,
          note: invitationNote,
          url: `${process.env.REACT_APP_REFERENCE_URL}/${reference.id}?token=${referenceToken}`,
        },
        environment: "production",
        recipient: email,
        templateId: "d-465a43a15c654f7e9761249554d68b7f",
      },
      headers = { Authorization: Cookie.get(`${global.COOKIE_NAME}-token`) };

    axios
      .post(url, data, { headers })
      .then(() => this.back())
      .catch((error) => {
        this.handleChange("loading", false);

        window.alert("Email invite unsuccessful.");
      });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Reference
        {...this.props}
        back={this.back}
        documentRemove={this.documentRemove}
        documentRenew={this.documentRenew}
        handleChange={this.handleChange}
        resetDocumentRenewal={this.resetDocumentRenewal}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: "userQuery",
    options: (props) => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
        reference: props.match.params.document,
      },
    }),
  }),
  graphql(ReferenceCreateMutation, { name: "referenceCreateMutation" }),
  graphql(ReferenceInviteMutation, { name: "referenceInviteMutation" }),
  graphql(ReferenceRemoveMutation, { name: "referenceRemoveMutation" }),
  graphql(ReferenceUpdateMutation, { name: "referenceUpdateMutation" })
)(ReferenceDocumentContainer);
